import { batch, useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import { selectors } from '@/builders/pizza/slice/pizza.slice';
import { clearDeal } from '@/builders/deals/slice/deal.slice';
import { clearDealToDisplay, updateDealRecipes } from '@/builders/deals/slice/userSelections.slice';
import Routes from '@/router/routes';
import useDeepEffect from '@/common/useDeepEffect';
import { RootState } from '@/rootStateTypes';
import { MELT, PIZZA } from '@/domain/constants';
import { onAddMultiStepDealToOrderButtonClick, onAddMultiStepDealToOrderSuccess } from '@/builders/deals/analytics/analytics';
import DealType from '@/builders/deals/DealTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

const getDeal = (state: RootState) => state.domain.deal;
const ADD_DEAL_TO_CART_BUTTON_TEXT = 'Add to order';

export const useAddDealToCartButton = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const deal = useSelector(getDeal);
  const pizza = useSelector(selectors.selectCartAddablePizza);
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const [{ enabled: unhideUpsellPageEnabled }] = useDecision('fr-web-3805-yum-unhide-upsell-page');

  const isMultiStepDeal = deal?.data?.type === DealType.MULTI_STEP;
  const redirectRoute = isYumEcomm && !unhideUpsellPageEnabled ? Routes.DEALS : Routes.UPSELL;

  useDeepEffect(() => {
    const { recipeType, id: stepId, index } = deal.userSelections.present.currentStep ?? {};
    if (!isMultiStepDeal && (recipeType === PIZZA || recipeType === MELT)) {
      dispatch(updateDealRecipes([{ item: pizza, stepId, index: index || 0 }]));
    }
  }, [pizza]);

  const onSuccesDealAddedToCart = () => router.push(redirectRoute).then(() => {
    batch(() => {
      dispatch(clearDeal());
      dispatch(clearDealToDisplay());
    });
  });

  const handleAnalytics = {
    onClickAnalytics: () => {
      analytics.push(() => onAddMultiStepDealToOrderButtonClick(deal));
    },
    onSuccessAddToCartAnalytics: () => {
      analytics.push(() => onAddMultiStepDealToOrderSuccess(deal));
    }
  };

  return {
    deal,
    onSuccesDealAddedToCart,
    isMultiStepDeal,
    redirectRoute,
    handleAnalytics,
    ADD_DEAL_TO_CART_BUTTON_TEXT
  };
};
