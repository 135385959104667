import React from 'react';
import AddToCartButton from '@/builders/pizza/AddToCart/AddToCartButton';
import { useAddDealToCartButton } from './hooks/useAddDealToCartButton';

interface Props {
  isEditFlow?: boolean;
  showQuantity?: boolean;
}

const AddDealToCartButton = ({ isEditFlow = false, showQuantity = false }: Props): JSX.Element => {
  const {
    deal,
    onSuccesDealAddedToCart,
    isMultiStepDeal,
    handleAnalytics,
    ADD_DEAL_TO_CART_BUTTON_TEXT
  } = useAddDealToCartButton();

  return (
    <AddToCartButton
      item={deal}
      quantity={deal?.data?.quantity}
      onFinishedAdd={onSuccesDealAddedToCart}
      buttonText={ADD_DEAL_TO_CART_BUTTON_TEXT}
      showQuantity={showQuantity}
      onClickAnalytics={isMultiStepDeal ? handleAnalytics.onClickAnalytics : undefined}
      onSuccessAddToCartAnalytics={isMultiStepDeal ? handleAnalytics.onSuccessAddToCartAnalytics : undefined}
      isEditFlow={isEditFlow}
    />
  );
};

export default AddDealToCartButton;
