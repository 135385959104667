import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import colors from '@/common/colors';

type DealPriceProps = {
  price: string;
};

const useStyles = makeStyles({
  price: {
    paddingTop: '3px',
    color: colors.gray900
  }
});

const DealPrice = ({ price }: DealPriceProps) => {
  const classes = useStyles();

  return (
    <Typography
      variant="h2"
      className={classes.price}
      aria-label="Cart total price"
    >
      {price}
    </Typography>
  );
};

export default DealPrice;
