import { makeStyles } from '@material-ui/core';
import { largeStartBreakpoint, mobileStartBreakpoint, smallMobileStartBreakpoint } from '@/materialUi/theme';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    marginTop: '3.5em',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      marginTop: 0
    }
  },
  cardBox: {
    width: '600px'
  },
  img: {
    borderRadius: '10px',
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    objectFit: 'contain',
    backgroundColor: '#000',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%',
      height: '188px',
      borderRadius: 0,
      backgroundColor: '#fff'
    }
  },
  imageGrid: {
    overflow: 'hidden',
    margin: '10px 0 0 59px',
    minWidth: '448px',
    minHeight: '337px',
    maxHeight: '337px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      minHeight: 'auto',
      maxHeight: '188px',
      minWidth: 'auto',
      margin: 0
    },
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      minWidth: '35%'
    }
  },
  btnContainer: {
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      '& > div:nth-of-type(2)': {
        width: '100%' // Example style
      }
    }
  }
}));

export default { useStyles };
