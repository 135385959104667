import React from 'react';
import { Grid } from '@material-ui/core';

import IndividualDealStep from '@/builders/deals/steps/IndividualDealStep';

import { Device, HiddenOnDevice } from '@/common/ResponsiveContext';
import CenteredContainer from '@/common/CenteredContainer';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import { EditBuilderSkeleton } from '@/common/EditBuilderSkeleton';
import AddToCartFooter from '@/common/AddToCartFooter';

import { SodiumWarningDisclaimer } from '@/clientCore/components/SodiumWarningDisclaimer/components/SodiumWarningDisclaimer';

import DealDetailsHeader from './components/DealDetailsHeader';
import AddDealToCartButton from './components/AddDealToCartButton';
import DealPrice from './components/DealPrice';

import { useDealDetails } from './hooks/useDealDetails';

import { useStyles } from './DealDetails.styles';
import { DealDetailsProps } from './types';

const DealDetails = ({ isEditFlow = false }: DealDetailsProps): JSX.Element => {
  const classes = useStyles();

  const {
    deal,
    onStepClick,
    summary,
    showEditBuilderSkeleton,
    multiStepSimplificationDecision,
    isShowSodiumAlertForStore
  } = useDealDetails();

  if (!deal || !deal.data.id) {
    return <></>;
  }

  return (
    <div data-testid="deal-builder">
      <HiddenOnDevice {...Device.DESKTOP}>
        <DealDetailsHeader name={summary?.name ?? ''} price={summary?.price ?? '0'} />
      </HiddenOnDevice>
      <CenteredContainer>
        <Grid className={classes.root} data-testid="build-your-deal-desktop">
          <HiddenOnDevice {...Device.MOBILE}>
            <DealDetailsHeader name={summary?.name ?? ''} price={summary?.price ?? '0'} />
          </HiddenOnDevice>
          <Grid container wrap="nowrap">
            <Grid item className={classes.cardBox}>
              {showEditBuilderSkeleton
                ? <EditBuilderSkeleton height="300px" />
                : (
                  <Grid data-testid="deal-steps">{deal.data.steps.map((step, index) => (
                    <IndividualDealStep
                      index={index}
                      key={step.id}
                      step={step}
                      onStepClick={onStepClick}
                      rules={deal.data.rules}
                      multiStepDecisionEnabled={multiStepSimplificationDecision.enabled}
                    />
                  ))}
                  </Grid>
                )}
              <CaloriesDisclaimer />
              {isShowSodiumAlertForStore && <SodiumWarningDisclaimer />}
            </Grid>
            <HiddenOnDevice {...Device.MOBILE}>
              <Grid item className={classes.imageGrid}>
                <img
                  loading="lazy"
                  src={summary?.image}
                  alt=""
                  className={classes.img}
                />
              </Grid>
            </HiddenOnDevice>
          </Grid>
        </Grid>
      </CenteredContainer>
      <AddToCartFooter>
        <CenteredContainer>
          <Grid
            container
            wrap="nowrap"
            direction="row"
            alignItems="center"
            spacing={2}
            className={classes.btnContainer}
          >
            <Grid item>
              <DealPrice price={summary?.price ?? '0'} />
            </Grid>
            <Grid item>
              <AddDealToCartButton isEditFlow={isEditFlow} />
            </Grid>
          </Grid>
        </CenteredContainer>
      </AddToCartFooter>
    </div>
  );
};

export default DealDetails;
